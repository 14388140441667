<template>
  <slot />
</template>

<script setup lang="ts">
import { useHead } from '#imports'

// Get the Google Analytics ID
const config = useRuntimeConfig()
const gTagID = config.public.gTagID

if (gTagID) {
  useHead({
    script: [
      {
        src: `https://www.googletagmanager.com/gtag/js?id=${gTagID}`,
        async: true,
      },
      {
        children: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${gTagID}');
        `,
      },
    ],
  })
}
</script>
