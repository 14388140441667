import { ofetch } from 'ofetch'

/**This plugin checks if the API returns a 402 status,
 * indicating that the user does not have an active subscription.
 * If necessary, create another plugin do not change/edit this. **/
export default defineNuxtPlugin((_nuxtApp) => {
  globalThis.$fetch = ofetch.create({
    onResponseError({ response }) {
      if (response.status === 402) {
        navigateTo('/payment')
      }
    },
  })
})
